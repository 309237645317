import React from 'react'
import styles from './VideoPlayer.module.css'

const VideoPlayer = ({ src, width = "100%", height = "100%", controls = true, autoPlay = true }) => {
  return (
    <video width={width} height={height} controls={controls} autoPlay={autoPlay}>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;